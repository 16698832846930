import React from "react"
import FormButton from "../button/FormButton"
import axios from "axios"
import { navigate } from "gatsby"
import { FormControlLabel } from "../section"

class RequestForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: "",
      email: "",
      mobile_no: "",
      zip: "",
      comment: "",
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      const searchParams = new URLSearchParams(window.location.search)
      if (
        searchParams.get("utm_source") != "" &&
        searchParams.get("utm_source") != null
      ) {
        sessionStorage.setItem("utm_source", searchParams.get("utm_source"))
        sessionStorage.setItem("utm_medium", searchParams.get("utm_medium"))
        sessionStorage.setItem("utm_campaign", searchParams.get("utm_campaign"))
        sessionStorage.setItem("utm_term", searchParams.get("utm_term"))
        sessionStorage.setItem("utm_content", searchParams.get("utm_content"))
      }
    }
  }

  handleChange(event) {
    const { id, value } = event.target
    this.setState({
      [id]: value,
    })
  }
  handleSubmit(event) {
    let utm_source_val = "website"
    let utm_medium_val = ""
    let utm_campaign_val = ""
    let utm_term_val = ""
    let utm_content_val = ""
    if (
      sessionStorage.getItem("utm_source") != "" &&
      sessionStorage.getItem("utm_source") != null
    ) {
      utm_source_val = sessionStorage.getItem("utm_source")
      utm_medium_val = sessionStorage.getItem("utm_medium")
      utm_campaign_val = sessionStorage.getItem("utm_campaign")
      utm_term_val = sessionStorage.getItem("utm_term")
      utm_content_val = sessionStorage.getItem("utm_content")
    }

    event.preventDefault()
    const { location } = this.props
    const currentUrl = window.location.href
    const data = {
      source: location.origin,
      form: "Request Form",
      page: currentUrl,
      first_name: this.state.first_name,
      email: this.state.email,
      mobile_no: this.state.mobile_no,
      comment: this.state.comment,
      utm_medium: utm_medium_val,
      utm_term: utm_term_val,
      utm_campaign: utm_campaign_val,
      utm_content: utm_content_val,
      lead_source: utm_source_val,
    }
    console.log(data, "Test")
    axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN },
    })
      .then(res => {
        //console.log("dataLayer start")
        if (typeof dataLayer !== "undefined") {
          window.dataLayer.push({
            event: "form_submit",
            formName: "website_forms",
            enhanced_conversion_data: {
              email: this.state.email,
              phone_number: this.state.mobile_no,
            },
          })
        }
        //console.log("dataLayer end")
        this.setState({ first_name: "", email: "", mobile_no: "", comment: "" })
        navigate("/thanks")
      })
      .catch(error => {
        alert(error)
      })
  }

  render() {
    return (
      <form id="rightform" onSubmit={this.handleSubmit}>
        <div className="form-group-bordered">
          <input
            type="text"
            id="first_name"
            className="form-control"
            onChange={this.handleChange}
            required
          />
          <FormControlLabel
            className={
              this.state.first_name !== ""
                ? "control-label active"
                : "control-label"
            }
          >
            Name
          </FormControlLabel>
        </div>
        <div className="form-group-bordered">
          <input
            type="email"
            id="email"
            className="form-control"
            onChange={this.handleChange}
            required
          />
          <FormControlLabel
            className={
              this.state.email !== "" ? "control-label active" : "control-label"
            }
          >
            Email
          </FormControlLabel>
        </div>
        <div className="form-group-bordered">
          <input
            type="tel"
            id="mobile_no"
            className="form-control"
            onChange={this.handleChange}
            pattern="[0-9]{10}"
            required
          />
          <FormControlLabel
            className={
              this.state.mobile_no !== ""
                ? "control-label active"
                : "control-label"
            }
          >
            Phone Number
          </FormControlLabel>
        </div>
        <div className="form-group-bordered">
          <input
            type="text"
            id="zip"
            className="form-control"
            onChange={this.handleChange}
            pattern="[0-9]{5}"
            required
          />
          <FormControlLabel
            className={
              this.state.mobile_no !== ""
                ? "control-label active"
                : "control-label"
            }
          >
            Zip Code
          </FormControlLabel>
        </div>
        <div className="form-group-bordered full-row">
          <textarea
            id="comment"
            className="form-control"
            onChange={this.handleChange}
            required
          />
          <FormControlLabel
            className={
              this.state.comment !== ""
                ? "control-label active"
                : "control-label"
            }
          >
            Ask Your Query
          </FormControlLabel>
        </div>
        <div className="form-action">
          <FormButton text="Submit" />
        </div>
      </form>
    )
  }
}
export default RequestForm